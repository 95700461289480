import { EmissionSourceDto } from '@_dto/global/vehicleCostDto/emissionSourceDto';
import { UsageLimitCalculationDto } from '@_dto/global/vehicleInfoDto/usageLimit/usageLimitCalculationDto';
import { UsageLimitDto } from '@_dto/global/vehicleInfoDto/usageLimit/usageLimitDto';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstant } from '@shared/GlobalConstant';
import { Application } from '@shared/models/business/application';
import { BatteryPerformance } from '@shared/models/business/batteryPerformance';
import { BatteryVersion } from '@shared/models/business/batteryVersion';
import { BodyType } from '@shared/models/business/bodyType';
import { EngineNumber } from '@shared/models/business/engineNumber';
import { Gcw } from '@shared/models/business/gcw';
import { IComparisonType } from '@shared/models/business/iComparisonType';
import { ICountry } from '@shared/models/business/iCountry';
import { ICurrency } from '@shared/models/business/iCurrency';
import { IRange } from '@shared/models/business/iRange';
import { IUnits } from '@shared/models/business/iUnits';
import { ModelEnergy } from '@shared/models/business/modelEnergy';
import { Ptac } from '@shared/models/business/ptac';
import { Pto } from '@shared/models/business/pto';
import { UsageType } from '@shared/models/business/usageType';
import { Observable } from 'rxjs';
import { CalculationDto } from '../../_dto/calculation/calculationDto';
import { ComparisonDto } from '../../_dto/global/comparisonDto';
import { Report } from '../../_dto/report/report';
import { CalculationResultDto } from '../../_dto/result/calculationResultDto';
import { CountryEmissionFactor } from '../../_models/vehicleCost/impactCo2/countryEmissionFactor';
import { EnergySubtype } from '../../_models/vehicleCost/impactCo2/energySubtype';

const httpExcelOptions = {
	headers: new HttpHeaders({
		Accept: 'text/html, application/xhtml+xml, */*',
		'Content-Type': 'application/json',
	}),
	withCredentials: true,
	responseType: 'blob' as 'json',
};

@Injectable({
	providedIn: 'root',
})
export class TcoBackendService {
	private backendApiUrl = GlobalConstant.BACKEND_ROOT;
	private backendApiUrlV2 = GlobalConstant.BACKEND_ROOT_V2;
	private urlVersion = '/app-version';
	private urlApplication = '/applications';
	private urlBodyType = '/body-types';
	private urlUsageType = '/usage-types';
	private urlBatteryVersion = '/battery-versions';
	private urlBatteryPerf = '/performances';
	private urlCalculation = '/calculation';
	private urlComparison = '/comparisons';
	private urlCountries = '/countries';
	private urlCurrencies = '/currencies';
	private urlEngineNumbers = '/engine-numbers';
	private urlEmissionFactor = '/emissions-factors';
	private urlEmissionSource = '/emissions-source';
	private urlEnergySubtype = '/energy-subTypes';
	private urlUsageLimit = '/usage-limit/results/';
	private urlModel = '/models';
	private urlPtac = '/ptac';
	private urlPto = '/ptos';
	private urlGcws = '/gcws';
	private urlRange = '/ranges';
	private urlReport = '/reports/';
	private urlReportExcel = this.urlReport + 'excel/';
	private urlReportPdf = this.urlReport + 'pdf';
	private urlReportExcelSummary = this.urlReportExcel + 'summary';
	private urlReportExcelOnDuration = this.urlReportExcel + 'on-duration';
	private urlUsageLimitEnergyDelivered = this.urlUsageLimit + 'delivery-energy/simulation';

	constructor(private http: HttpClient, private translateService: TranslateService) {}

	public getVersionApp(): Observable<string> {
		return this.http.get<string>(this.backendApiUrl + this.urlVersion);
	}

	public getEmissionFactorByCountry(countryCode: string): Observable<CountryEmissionFactor> {
		return this.http.get<any>(this.backendApiUrl + this.urlEmissionFactor + '/' + countryCode);
	}

	public getEmissionSourceByCountry(countryCode: string, language: string): Observable<EmissionSourceDto[]> {
		const queryParams = new HttpParams().set('countryCode', countryCode).set('language', language);
		return this.http.get<EmissionSourceDto[]>(this.backendApiUrl + this.urlEmissionSource, { params: queryParams });
	}

	public getModelByCode(code: string, lang: string): Observable<ModelEnergy> {
		const queryParams = new HttpParams().set('countryCode', lang);
		return this.http.get<ModelEnergy>(this.backendApiUrl + this.urlModel + '/' + code, { params: queryParams });
	}

	public getEngineNumberById(engineNumberId: number): Observable<EngineNumber> {
		return this.http.get<EngineNumber>(this.backendApiUrl + this.urlEngineNumbers + '/' + engineNumberId);
	}

	public getAllBodyTypes(lang: string): Observable<BodyType[]> {
		const queryParams = new HttpParams().set('countryCode', lang);
		return this.http.get<BodyType[]>(this.backendApiUrl + this.urlBodyType, { params: queryParams });
	}

	public getBodyTypeById(bodyTypeId: number, lang: string): Observable<BodyType> {
		const queryParams = new HttpParams().set('countryCode', lang);
		return this.http.get<BodyType>(this.backendApiUrl + this.urlBodyType + '/' + bodyTypeId, { params: queryParams });
	}

	public getAllUsageTypes(lang: string): Observable<UsageType[]> {
		const queryParams = new HttpParams().set('countryCode', lang);
		return this.http.get<UsageType[]>(this.backendApiUrl + this.urlUsageType, { params: queryParams });
	}

	public getUsageTypeById(usageTypeId: number, lang: string): Observable<UsageType> {
		const queryParams = new HttpParams().set('countryCode', lang);
		return this.http.get<BodyType>(this.backendApiUrl + this.urlUsageType + '/' + usageTypeId, { params: queryParams });
	}

	public getBatteryByCode(batteryCode: string): Observable<BatteryVersion> {
		return this.http.get<BatteryVersion>(this.backendApiUrlV2 + this.urlBatteryVersion + '/' + batteryCode);
	}

	public getBatteryPerformanceByBatteryCode(batteryCode: string): Observable<BatteryPerformance[]> {
		return this.http.get<BatteryPerformance[]>(this.backendApiUrlV2 + this.urlBatteryVersion + '/' + batteryCode + this.urlBatteryPerf);
	}

	public getEnergyDelivered(yearlyMileage: number, consumption: number, contractYear: number): Observable<number> {
		const queryParams = new HttpParams()
			.set('yearlyMileage', yearlyMileage)
			.set('consumption', consumption)
			.set('contractDurationInYears', contractYear);

		return this.http.get<number>(this.backendApiUrl + this.urlUsageLimitEnergyDelivered, {
			params: queryParams,
		});
	}

	public getExcelSummaryReport(report: Report): Observable<Report> {
		return this.http.post<any>(this.backendApiUrl + this.urlReportExcelSummary, report, httpExcelOptions);
	}

	public getExcelReport(report: Report): Observable<Report> {
		return this.http.post<any>(this.backendApiUrl + this.urlReportExcel, report, httpExcelOptions);
	}

	public getExportOnDurationExcel(report: Report): Observable<Report> {
		return this.http.post<any>(this.backendApiUrl + this.urlReportExcelOnDuration, report, httpExcelOptions);
	}

	public getPdfReport(report: Report): Observable<Blob> {
		return this.http.post<any>(this.backendApiUrl + this.urlReportPdf, report, httpExcelOptions);
	}

	public getAllCurrencies(): Observable<ICurrency[]> {
		return this.http.get<ICurrency[]>(this.backendApiUrl + this.urlCurrencies);
	}

	public getComparisonList(): Observable<ComparisonDto[]> {
		return this.http.get<ComparisonDto[]>(this.backendApiUrlV2 + this.urlComparison);
	}

	public getAllEnergySubtypes(energyType: string): Observable<EnergySubtype[]> {
		const queryParams = new HttpParams().set('energyType', energyType);
		return this.http.get<EnergySubtype[]>(this.backendApiUrl + this.urlEnergySubtype + '/by-energy-type', { params: queryParams });
	}

	public getAllCountries(lang: string): Observable<ICountry[]> {
		const queryParams = new HttpParams().set('countryCode', lang);
		return this.http.get<ICountry[]>(this.backendApiUrl + this.urlCountries, {
			params: queryParams,
		});
	}

	public getAllRanges(): Observable<IRange[]> {
		return this.http.get<IRange[]>(this.backendApiUrl + this.urlRange);
	}

	public getAllEngineNumber(): Observable<EngineNumber[]> {
		return this.http.get<EngineNumber[]>(this.backendApiUrl + this.urlEngineNumbers);
	}

	//TODO: has to be done with the back => HTTPS Get
	public getUnits(): Observable<IUnits[]> {
		let tempObs = new Observable<IUnits[]>((observer) => {
			let units = new Array<IUnits>();
			const u = {} as IUnits;
			u.id = 1;
			u.name = 'MPG';
			units.push(u);

			const u2 = {} as IUnits;
			u2.id = 2;
			u2.name = 'L/100km';
			units.push(u2);

			observer.next(units);
			observer.complete();
		});
		return tempObs;
	}

	//TODO: has to be done with the back => HTTPS Get
	public getComparisonTypes(): Observable<IComparisonType[]> {
		let tempObs = new Observable<IComparisonType[]>((observer) => {
			let cts = new Array<IComparisonType>();
			const ct = {} as IComparisonType;
			ct.id = 1;
			const ct2 = {} as IComparisonType;
			ct2.id = 2;
			const ct3 = {} as IComparisonType;
			ct3.id = 3;
			const ct4 = {} as IComparisonType;
			ct4.id = 4;
			const ct5 = {} as IComparisonType;
			ct5.id = 5;
			this.translateService
				.stream([
					'form.comparison.ed',
					'form.comparison.egd',
					'form.comparison.eg',
					'form.comparison.elec',
					'form.comparison.elecComp',
					'form.comparison.elecRT',
				])
				.subscribe((translation) => {
					ct.name = translation['form.comparison.ed'];
					ct2.name = translation['form.comparison.egd'];
					ct3.name = translation['form.comparison.eg'];
					ct4.name = translation['form.comparison.elec'];
					ct5.name = translation['form.comparison.elecComp'];
				});
			cts.push(ct, ct2, ct3, ct4, ct5);
			observer.next(cts);
			observer.complete();
		});
		return tempObs;
	}

	public getApplicationsByRangeId(rangedId: number, lang: string): Observable<Application[]> {
		const params = new HttpParams().set('rangeId', rangedId).set('countryCode', lang);
		return this.http.get<Application[]>(this.backendApiUrl + this.urlApplication, { params: params });
	}

	public getModelsByRangeId(rangedId: number, lang: string): Observable<ModelEnergy[]> {
		const queryParams = new HttpParams().set('rangeId', rangedId).set('countryCode', lang);
		return this.http.get<ModelEnergy[]>(this.backendApiUrl + this.urlModel, { params: queryParams });
	}

	public getPtacByModelcode(lang: string, modelCode?: string): Observable<Ptac[]> {
		if (modelCode) {
			const queryParams = new HttpParams().set('modelCode', modelCode).set('countryCode', lang);
			return this.http.get<Ptac[]>(this.backendApiUrl + this.urlPtac, {
				params: queryParams,
			});
		} else {
			return this.http.get<Ptac[]>(this.backendApiUrl + this.urlPtac);
		}
	}

	public getAllGcwsByModelcode(modelCode?: string): Observable<Gcw[]> {
		if (modelCode) {
			const queryParams = new HttpParams().set('modelCode', modelCode);
			return this.http.get<Gcw[]>(this.backendApiUrl + this.urlGcws, {
				params: queryParams,
			});
		} else {
			return this.http.get<Gcw[]>(this.backendApiUrl + this.urlGcws);
		}
	}

	public getAllPtosByRangeId(rangeId: number, countryCode: string): Observable<Pto[]> {
		const queryParams = new HttpParams().set('idRange', rangeId).set('countryCode', countryCode);
		return this.http.get<Pto[]>(this.backendApiUrl + this.urlPto, {
			params: queryParams,
		});
	}

	public getBatteryVersionByModelcodeAndPto(modelCode: string, ptoId: number): Observable<BatteryVersion[]> {
		const queryParams = new HttpParams().set('modelCode', modelCode).set('idPto', ptoId);
		return this.http.get<BatteryVersion[]>(this.backendApiUrlV2 + this.urlBatteryVersion, { params: queryParams });
	}

	public computeUsageLimit(usageLimitCalculation: UsageLimitCalculationDto): Observable<UsageLimitDto> {
		return this.http.post<UsageLimitDto>(this.backendApiUrl + this.urlUsageLimit, usageLimitCalculation);
	}

	public computeComparison(calculation: CalculationDto): Observable<CalculationResultDto> {
		return this.http.post<CalculationResultDto>(this.backendApiUrl + this.urlCalculation, calculation);
	}

	public saveComparison(compDto: ComparisonDto): Observable<ComparisonDto> {
		return this.http.post<ComparisonDto>(this.backendApiUrlV2 + this.urlComparison, compDto);
	}

	public updateComparison(id: number, compDto: ComparisonDto): Observable<ComparisonDto> {
		return this.http.put<ComparisonDto>(`${this.backendApiUrlV2}${this.urlComparison}/${id}`, compDto);
	}

	public deleteComparison(id: number): Observable<ComparisonDto> {
		return this.http.delete<ComparisonDto>(this.backendApiUrlV2 + this.urlComparison + '/' + id);
	}
}
