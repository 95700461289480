import { environment } from 'src/environments/environment';

export class GlobalConstant {
	// API ROUTE
	static readonly BACKEND_ROOT = environment.backEndApiUrl + '/api/v1';
	static readonly BACKEND_ROOT_V2 = environment.backEndApiUrl + '/api/v2';
	// Range ID
	static readonly RANGE_LCV = 1;
	static readonly RANGE_MDV = 2;
	static readonly RANGE_HDV = 3;
	// Comparison Type ID
	static readonly COMPTYPE_ED = 1;
	static readonly COMPTYPE_EDG = 2;
	static readonly COMPTYPE_EG = 3;
	static readonly COMPTYPE_E = 4;
	static readonly COMPTYPE_EE = 5;
	// Energy value name
	static readonly ELECTRIC = 'electric';
	static readonly DIESEL = 'diesel';
	static readonly GAS = 'gas';
	static readonly ELEC_COMP = 'electricComp';
	static readonly BX = 'Bx';

	// Usage Limit Status
	static readonly EXCEED = 'KO';
	static readonly NOT_EXCEED = 'OK';
	static readonly NOT_APPLICABLE = 'NA';
	static readonly CONSUMPTION_VIGILANCE = 'ConsumptionVigilance';
	// Component Name
	static readonly VEHICLE_USAGE = 'vehicle-usage';
	static readonly VEHICLE_CONFIG = 'vehicle-config';
	static readonly ENERGY_USAGE = 'energy-usage';
	static readonly USAGE_LIMIT = 'usage-limit';
	// Component Category
	static readonly TOT_DELIVERED_ENERGY = 'totalDeliveredEnergy';
	static readonly DAILY_MISSION = 'dailyMission';
	static readonly CONTRACT_DURATION = 'contratDuration';
	// Charging Station Modal Category
	static readonly MAX_COEF = 100;

	static readonly STEPS = [
		{
			stepIndex: 0,
			isComplete: false,
			name: 'home',
			route: '',
			fragment: '',
		},
		{
			stepIndex: 1,
			isComplete: false,
			name: 'vehicleUsage',
			route: '/vehicle-info',
			fragment: '',
		},
		{
			stepIndex: 2,
			isComplete: false,
			name: 'vehicleConfig',
			route: '/vehicle-info',
			fragment: 'vehicleconfig',
		},
		{
			stepIndex: 3,
			isComplete: false,
			name: 'energyUsage',
			route: '/vehicle-info',
			fragment: 'energyusage',
		},
		{
			stepIndex: 4,
			isComplete: false,
			name: 'usageLimit',
			route: '/vehicle-info',
			fragment: 'usagelimit',
		},
		{
			stepIndex: 5,
			isComplete: false,
			name: 'financials',
			route: '/vehicle-cost',
			fragment: '',
		},
		{
			stepIndex: 6,
			isComplete: false,
			name: 'energyCost',
			route: '/vehicle-cost',
			fragment: 'energycost',
		},
		{
			stepIndex: 7,
			isComplete: false,
			name: 'incentivesTax',
			route: '/vehicle-cost',
			fragment: 'incentives',
		},
		{
			stepIndex: 8,
			isComplete: false,
			name: 'penalty',
			route: '/vehicle-cost',
			fragment: 'penalty',
		},
		{
			stepIndex: 9,
			isComplete: false,
			name: 'costOfTransport',
			route: '/vehicle-cost',
			fragment: 'costoftransport',
		},
		{
			stepIndex: 10,
			isComplete: false,
			name: 'results',
			route: '/result',
			fragment: '',
		},
	];
}
